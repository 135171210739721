<template>
  <div>
    <div>
      <NavBar></NavBar>
    </div>
    <div>
      <Header></Header>
      <div style="overflow-y: auto;">
        <div class="container text-center">
          <br />
          <h3 class="h4" style="color: #a19c9c">
            {{
              languaje == "es"
                ? "Ganancias de " + meses[mes]
                : months[mes] + " Earnings"
            }}
          </h3>
          <table class="table table-hover">
            <thead>
              <tr style="background-color: #a19c9c">
                <th class="h5 text-white">
                  {{ $t("reports.timeFrame") }}
                </th>
                <th class="h5 text-white">
                  {{ $t("reports.amount") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="h6">
                  {{ $t("reports.dailySales") }}
                </th>
                <td class="h6">${{ getSales(todaySales) }}</td>
              </tr>
              <tr>
                <th class="h6">
                  {{ $t("reports.weeklySales") }}
                </th>
                <td class="h6">${{ getSales(weekSales) }}</td>
              </tr>
              <tr>
                <th class="h6">
                  {{ $t("reports.monthlySale") }}
                </th>
                <td class="h6">${{ getSales(monthSales) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container text-center mt-6">
          <br />
          <h3 class="h4" style="color: #a19c9c">
            {{ $t("reports.annualEarnings") }}
          </h3>
          <table class="table table-hover">
            <thead>
              <tr style="background-color: #a19c9c">
                <th class="h5 text-white">
                  {{ $t("reports.month") }}
                </th>
                <th class="h5 text-white">
                  {{ $t("reports.gain") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="h6">
                  {{ languaje == "es" ? meses[0] : months[0] }}
                </th>
                <td class="h6">${{ getSales(earns[0]) }}</td>
              </tr>
              <tr v-for="i of mes" :key="i">
                <th class="h6">
                  {{ languaje == "es" ? meses[i] : months[i] }}
                </th>
                <td class="h6">${{ getSales(earns[i]) }}</td>
              </tr>

              <tr>
                <th class="h5" style="font-weight: bold">
                  {{ $t("reports.totalAnnual") }}
                </th>
                <td class="h5" style="font-weight: bold">
                  ${{ getSales(allSales) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center">
          <div class="my-2">
            <v-btn small color="secondary" dark @click="getIvuReport()">
              Obtener Reporte de IVU
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from "../../components/NavBar.vue";
import Header from "../../components/Header.vue";
import Parse from "parse";
import xlsx from "xlsx";
export default {
  name: "Reports",
  components: {
    NavBar,
    Header
  },
  data() {
    return {
      allSales: null,
      todaySales: null,
      monthSales: null,
      weekSales: null,
      storeId: null,
      languaje: "",
      fecha: null,
      meses: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      ],
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      mes: 0,
      earns: [],
      totalEarns: 0
    };
  },
  mounted() {
    this.fecha = new Date();
    this.languaje = navigator.language || navigator.userLanguage;
    this.languaje = this.languaje.substr(0, 2);
    this.getStoreId();
  },
  methods: {
    getIvuReport() {
      Parse.Cloud.run("getIVUReport", {
        storeId: this.storeId.id
      })
        .then(result => {
          const ws = xlsx.utils.json_to_sheet(result);
          const wb = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, "Sheet1");
          xlsx.writeFile(wb, "Reporte productos activos Farmacia.xlsx");
        })
        .catch(err => {
          console.log(err);
          this.$swal({
            title: "Ah ocurrido un error",
            icon: "error",
            html: err
          });
        });
    },
    firstDayWeek(d) {
      let day = d.getDay();
      let diff = 0 - day;
      return new Date(d.setDate(d.getDate() + diff));
    },
    lastDayWeek(d) {
      let day = d.getDay();
      let diff = 0 - day + 6;
      return new Date(d.setDate(d.getDate() + diff));
    },
    async getStoreId() {
      this.storeId = await Parse.Cloud.run("getStore", {
        userId: Parse.User.current().id
      });
      console.log(this.storeId);
      if (this.storeId != null) {
        this.getTodaySales();
        this.getMonthSales();
        this.getTotalSales();
        this.getWeekSales();
        let meses = new Date();
        this.mes = meses.getMonth();
        for (let a = 0; a <= this.mes; a++) {
          this.getSalesByMonth(a);
        }
      }
    },
    getSales(sales) {
      let data = 0;
      if (sales) {
        data = sales;
      }
      return parseFloat(data).toFixed(2);
    },
    async getTodaySales() {
      let endDate = this.fecha;
      let startDate = this.fecha;
      let query = new Parse.Query("ServiceRequest");
      query.equalTo(
        "store",
        Parse.Object.extend("Store")
          .createWithoutData(this.storeId.id)
          .toPointer()
      );
      query.count().then(total => {
        query.limit(total);
        query.equalTo("status", "F");
        query.lessThan("createdAt", endDate);
        query.greaterThan("createdAt", startDate);
        query.find().then(orders => {
          for (let order of orders) {
            if (order.get("storeGain")) {
              this.todaySales += order.get("storeGain");
            }
          }
        });
      });
    },
    async getTotalSales() {
      let startDate = new Date(this.fecha.getFullYear(), 1, 1); //this.fecha;
      let endDate = this.fecha;
      let query = new Parse.Query("ServiceRequest");
      query.equalTo(
        "store",
        Parse.Object.extend("Store")
          .createWithoutData(this.storeId.id)
          .toPointer()
      );
      query.count().then(total => {
        query.limit(total);
        query.equalTo("status", "F");
        query.lessThan("createdAt", endDate);
        query.greaterThan("createdAt", startDate);
        query.find().then(orders => {
          for (let order of orders) {
            if (order.get("storeGain")) {
              this.allSales += order.get("storeGain");
            }
          }
        });
      });
    },
    async getMonthSales() {
      let startDate = new Date(
        this.fecha.getFullYear(),
        this.fecha.getMonth(),
        1
      ); //this.fecha;
      let endDate = this.fecha;
      let query = new Parse.Query("ServiceRequest");
      query.equalTo(
        "store",
        Parse.Object.extend("Store")
          .createWithoutData(this.storeId.id)
          .toPointer()
      );
      query.count().then(total => {
        query.limit(total);
        query.equalTo("status", "F");
        query.lessThan("createdAt", endDate);
        query.greaterThan("createdAt", startDate);
        query.find().then(orders => {
          for (let order of orders) {
            if (order.get("storeGain")) {
              this.monthSales += order.get("storeGain");
            }
          }
        });
      });
    },
    async getWeekSales() {
      // this.monthSales = await Parse.Cloud.run("getMonthlyEarnigs");
      let startDate = this.firstDayWeek(this.fecha); //this.fecha;
      let endDate = this.lastDayWeek(this.fecha);
      let query = new Parse.Query("ServiceRequest");
      query.equalTo(
        "store",
        Parse.Object.extend("Store")
          .createWithoutData(this.storeId.id)
          .toPointer()
      );
      query.count().then(total => {
        query.limit(total);
        query.equalTo("status", "F");
        query.lessThan("createdAt", endDate);
        query.greaterThan("createdAt", startDate);
        query.find().then(orders => {
          for (let order of orders) {
            if (order.get("storeGain")) {
              this.weekSales += order.get("storeGain");
            }
          }
        });
      });
    },
    async getSalesByMonth(mes) {
      let sumatoria = 0;
      let startDate = new Date(this.fecha.getFullYear(), mes, 1); //this.fecha;
      let endDate = new Date(this.fecha.getFullYear(), mes + 1, 1);
      let query = new Parse.Query("ServiceRequest");
      query.equalTo(
        "store",
        Parse.Object.extend("Store")
          .createWithoutData(this.storeId.id)
          .toPointer()
      );
      query.count().then(total => {
        query.limit(total);
        query.equalTo("status", "F");
        query.lessThan("createdAt", endDate);
        query.greaterThan("createdAt", startDate);
        query.find().then(orders => {
          if (orders.length !== 0) {
            for (let order of orders) {
              if (order.get("storeGain")) {
                sumatoria += order.get("storeGain");
              }
            }
          } else {
            sumatoria = 0;
          }
          this.earns[mes] = sumatoria;
        });
      });
    }
  }
};
</script>
